// import React, { useState, useEffect, useRef } from "react";
// import io from "socket.io-client";
// import axios from "axios";
// import ExcelData from "./ExcelData";
// import Sidebar from "./Sidebar";
// import Navbar from "./Navbar";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const ExcelUpload = () => {
//   const [file, setFile] = useState(null);
//   const [displayedRecords, setDisplayedRecords] = useState(0);
//   const userDetails = JSON.parse(localStorage.getItem("user"));
//   const [isUploaded, setIsUploaded] = useState(false);
//   const [refreshData, setRefreshData] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [isCountComplete, setIsCountComplete] = useState(false);

//   // Create a ref for the file input
//   const fileInputRef = useRef(null);

//   useEffect(() => {
//     const socket = io(process.env.REACT_APP_BASE_URL);
//     socket.on("uploadProgress", (data) => {
//       setIsUploaded(true);
//       if (data.totalInserted > 0) {
//         const duration = 55000;
//         const intervalTime = 100; // Interval to update the counter (0.1 second)
//         const steps = duration / intervalTime;
//         const incrementStep = Math.ceil(data.totalInserted / steps); // Calculate the increment value for each step
//         let count = 1;
//         setDisplayedRecords(1); // Start counter from 1
//         const interval = setInterval(() => {
//           setDisplayedRecords((prev) => {
//             const next = Math.min(prev + incrementStep, data.totalInserted); // Increment the counter, but don't exceed totalInserted
//             if (next >= data.totalInserted) {
//               clearInterval(interval); // Stop the interval when total is reached
//               setRefreshData(true); // Trigger re-render of ExcelData when counting stops
//               setIsCountComplete(true); // Set count complete flag
//               setLoading(false); // Hide the loader when counting stops
//               // Set timeout to reset form after 10 seconds
//               setTimeout(() => {
//                 // Reset form values
//                 setFile(null);
//                 setDisplayedRecords(0);
//                 setIsUploaded(false);
//                 setIsCountComplete(false);
//                 setRefreshData(false);
//                 // Manually reset the file input
//                 if (fileInputRef.current) {
//                   fileInputRef.current.value = ""; // Reset the file input field
//                 }
//               }, 10000); // 10 seconds delay before form reset
//             }
//             return next;
//           });
//         }, intervalTime);
//       }
//     });
//     return () => {
//       socket.disconnect(); // Clean up on component unmount
//     };
//   }, []);

//   useEffect(() => {
//     if (refreshData) {
//       setRefreshData(false);
//     }
//   }, [refreshData]);

//   const handleFileChange = (e) => {
//     const selectedFile = e.target.files[0];

//     const fileType = selectedFile ? selectedFile.name.split(".").pop() : "";

//     // Check if the file is not an .xlsx file
//     if (fileType !== "xlsx") {
//       toast.error("Please upload a valid .xlsx file");
//       setFile(null); // Reset the file state
//       if (fileInputRef.current) {
//         fileInputRef.current.value = ""; // Reset the file input field
//       }
//     } else {
//       setFile(selectedFile); // Set the file if valid
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!file) return;

//     const formData = new FormData();
//     formData.append("excel", file);
//     setLoading(true); // Start the loader

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}excel-data-upload`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: `Bearer ${userDetails.token}`,
//           },
//         }
//       );
//       // Handle response for record limit exceeded
//       if (response.status === 200 && response.data.message) {
//         toast.success(response.data.message);
//         setLoading(true);
//         return; // Exit the function if the limit is exceeded
//       }

//       if (response.data.records) {
//         setIsUploaded(true); // Indicate upload completion
//       }
//     } catch (error) {
//       if (fileInputRef.current) {
//         fileInputRef.current.value = ""; // Reset the file input field
//       }
//       setLoading(false);
//       toast.error(error.response.data.message);
//     } finally {
//       // Loading state will be managed in the socket event
//     }
//   };

//   return (
//     <>
//       <ToastContainer />
//       <div id="wrapper">
//         <Sidebar />
//         <div id="content-wrapper" className="d-flex flex-column">
//           <div id="content">
//             <Navbar />
//             <div className="container-fluid">
//               <div className="card shadow mb-4">
//                 <div className="card-header py-3 d-flex justify-content-between">
//                   <h6 className="m-0 font-weight-bold text-primary">
//                     Bank Statement Upload
//                   </h6>
//                 </div>
//                 <div className="card-body">
//                   <div className="row">
//                     <div className="col-4"></div>
//                     <div className="col-4 border py-2">
//                       <form onSubmit={handleSubmit}>
//                         <div className="form-group">
//                           <input
//                             type="file"
//                             className="form-control"
//                             onChange={handleFileChange}
//                             ref={fileInputRef} // Attach the ref to the input
//                           />
//                         </div>
//                         <div className="text-center">
//                           <button
//                             type="submit"
//                             className="btn btn-primary"
//                             disabled={loading || isCountComplete}
//                           >
//                             Save & Exit
//                           </button>

//                           {loading && (
//                             <div style={{ marginTop: "10px" }}>
//                               <div
//                                 className="spinner-border text-primary"
//                                 role="status"
//                               >
//                                 <span className="sr-only">Loading...</span>
//                               </div>
//                               <h3 style={{ fontWeight: "bold", color: "blue" }}>
//                                 Uploading...
//                               </h3>
//                             </div>
//                           )}
//                           {isUploaded && isCountComplete && (
//                             <div style={{ marginTop: "10px" }}>
//                               <div className="wrapper">
//                                 <div className="circle">
//                                   <div className="checkMark"></div>
//                                 </div>
//                               </div>
//                             </div>
//                           )}

//                           <h3 style={{ fontWeight: "bold", color: "green" }}>
//                             {displayedRecords}
//                           </h3>
//                         </div>
//                       </form>
//                     </div>
//                     <div className="col-4"></div>
//                   </div>

//                   <div className="table-responsive">
//                     <ExcelData refresh={refreshData} />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <footer className="sticky-footer bg-white">
//             <div className="container my-auto">
//               <div className="copyright text-center my-auto">
//                 <span>Copyright &copy; Banking SMS 2024</span>
//               </div>
//             </div>
//           </footer>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ExcelUpload;

// -----------------------------------------------------------

import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import axios from "axios";
import ExcelData from "./ExcelData";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const ExcelUpload = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [displayedRecords, setDisplayedRecords] = useState(0);
  // const userDetails = JSON.parse(localStorage.getItem("user"));
  const [isUploaded, setIsUploaded] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCountComplete, setIsCountComplete] = useState(false);

  // Create a ref for the file input
  const fileInputRef = useRef(null);

  const handleCounter = (data) => {
    setIsUploaded(true);
    if (data > 0) {
      // const duration = 55000;
      const duration = 10 * 1000;
      const intervalTime = 100; // Interval to update the counter (0.1 second)
      const steps = duration / intervalTime;
      const incrementStep = Math.ceil(data / steps); // Calculate the increment value for each step
      let count = 1;
      setDisplayedRecords(1); // Start counter from 1
      const interval = setInterval(() => {
        setDisplayedRecords((prev) => {
          const next = Math.min(prev + incrementStep, data); // Increment the counter, but don't exceed totalInserted
          if (next >= data) {
            clearInterval(interval); // Stop the interval when total is reached
            setRefreshData(true); // Trigger re-render of ExcelData when counting stops
            setIsCountComplete(true); // Set count complete flag
            setLoading(false); // Hide the loader when counting stops
            // Set timeout to reset form after 10 seconds
            setTimeout(() => {
              // Reset form values
              setFile(null);
              setDisplayedRecords(0);
              setIsUploaded(false);
              setIsCountComplete(false);
              setRefreshData(false);
              // Manually reset the file input
              if (fileInputRef.current) {
                fileInputRef.current.value = ""; // Reset the file input field
              }
            }, 10000); // 10 seconds delay before form reset
          }
          return next;
        });
      }, intervalTime);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    const fileType = selectedFile ? selectedFile.name.split(".").pop() : "";

    // Check if the file is not an .xlsx file
    if (fileType !== "xlsx") {
      toast.error("Please upload a valid .xlsx file");
      setFile(null); // Reset the file state
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset the file input field
      }
    } else {
      setFile(selectedFile); // Set the file if valid
    }
  };

  const handleSubmit = async (e) => {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (!userDetails || !userDetails.token) {
      navigate("/logout");
    }

    e.preventDefault();

    if (!file) return;

    const formData = new FormData();
    formData.append("excel", file);
    setLoading(true); // Start the loader

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}excel-data-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userDetails.token}`,
          },
        }
      );

      handleCounter(response.data.insertedCount);
      // Handle response for record limit exceeded
      if (response.status === 200 && response.data.message) {
        if (response.data.insertedCount === 0 && fileInputRef.current) {
          toast.error("All records are failed");
          fileInputRef.current.value = "";
          setLoading(false);
          setRefreshData(true);
          return; //
        }
        toast.success(response.data.message);
        setLoading(true);
        return; // Exit the function if the limit is exceeded
      }

      if (response.data.records) {
        setIsUploaded(true);
      }
    } catch (error) {
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset the file input field
      }
      setLoading(false);
      toast.error(error.response.data.message);
    } finally {
      // Loading state will be managed in the socket event
    }
  };

  return (
    <>
      <ToastContainer />
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Bank Statement Upload
                  </h6>
                  <a href="/sample-upload-file.xlsx" className="btn btn-outline-primary btn-sm" download>
                    <i className="fa fa-download mr-1"></i> Download Sample File
                  </a>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-4"></div>
                    <div className="col-4 border py-2">
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <input
                            type="file"
                            className="form-control"
                            onChange={handleFileChange}
                            ref={fileInputRef} // Attach the ref to the input
                          />
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading || isCountComplete}
                          >
                            Save & Exit
                          </button>

                          {loading && (
                            <div style={{ marginTop: "10px" }}>
                              <div
                                className="spinner-border text-primary"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                              <h3 style={{ fontWeight: "bold", color: "blue" }}>
                                Uploading...
                              </h3>
                            </div>
                          )}
                          {isUploaded && isCountComplete && (
                            <div style={{ marginTop: "10px" }}>
                              <div className="wrapper">
                                <div className="circle">
                                  <div className="checkMark"></div>
                                </div>
                              </div>
                            </div>
                          )}

                          <h3 style={{ fontWeight: "bold", color: "green" }}>
                            {displayedRecords}
                          </h3>
                        </div>
                      </form>
                    </div>
                    <div className="col-4"></div>
                  </div>

                  <div className="table-responsive">
                    <ExcelData refresh={refreshData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>Copyright &copy; Blue Iris Software Trading LLC</span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default ExcelUpload;
