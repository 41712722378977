import DataTable from "react-data-table-component";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import tableColumns from "./TableColumns";
import Papa from "papaparse";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { io } from "socket.io-client";
import { useNavigate } from "react-router-dom";

const Transactions = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [filterActive, setFilterActive] = useState(false);
  const [dates, setDates] = useState({
    startdate: "",
    enddate: "",
  });

  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [searchColumn, setSearchColumn] = useState("");
  const [inputData, setInputData] = useState("");

  // const userDetails = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    // const fetchDataAndSetupSocket = async () => {
    // await fetchData(currentPage, pageSize); // Fetch initial data
    fetchData(currentPage, pageSize); // Fetch initial data
    // this is for local "process.env.REACT_APP_BASE_URL" and this for stage "process.env.REACT_APP_BASE_URL_APP"
    // const socket = io(process.env.REACT_APP_BASE_URL_APP, {
    //   transports: ["websocket", "polling"],
    // });

    const socket = io(process.env.REACT_APP_BASE_URL_APP); //this use for prod env

    if (filterActive) {
      return () => {
        socket.disconnect();
      };
    }

    socket.on("smsCreated", (data) => {
      // Clear the existing data and fetch fresh data from the server
      fetchData(currentPage, pageSize); // Fetch the latest transactions
    });

    return () => {
      socket.disconnect();
    };
    // };

    // fetchDataAndSetupSocket();
  }, [currentPage, pageSize, dates.startdate, dates.enddate, filterActive]); // Dependencies as needed

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1); // Reset to first page when page size changes
  };

  const handleDates = (e) => {
    const { name, value } = e.target;
    setDates({
      ...dates,
      [name]: value,
    });
  };

  const toggleFilter = () => {
    if (filterActive) {
      // Clear the filter
      setDates({
        startdate: "",
        enddate: "",
      });
      setFilterActive(false);
      setCurrentPage(1); // Reset to first page
      setSearchData("");
      setSearchColumn("");
      setInputData("");
    } else {
      // Apply the filter
      setFilterActive(true);
      setCurrentPage(1); // Reset to first page
      setInputData(searchData);
    }
  };

  const fetchData = (page, limit) => {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (!userDetails || !userDetails.token) {
      navigate("/logout");
    }

    if (filterActive) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}transaction-filter?search=${inputData}&column=${searchColumn}&page=${page}&limit=${limit}&startdate=${dates.startdate}&enddate=${dates.enddate}`,
          {
            headers: {
              Authorization: `Bearer ${userDetails.token}`,
            },
          }
        )
        .then((response) => {
          setData(response.data.result);
          setTotalRows(response.data.totalRecords);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          // console.error("Error fetching transaction data:", error);
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}index?page=${page}&limit=${limit}`,
          {
            headers: {
              Authorization: `Bearer ${userDetails.token}`,
            },
          }
        )
        .then((response) => {
          setData(response.data.result);
          setTotalRows(response.data.totalRecords);
        })
        .catch((error) => {
          console.error("Error fetching transaction data:", error);
        });
    }
  };

  const columns = tableColumns(currentPage, pageSize);

  const downloadCSV = async () => {
    setLoading(true);
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (!userDetails || !userDetails.token) {
      setLoading(false);
      navigate("/logout");
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}handle-csv?search=${inputData}&column=${searchColumn}&startdate=${dates.startdate}&enddate=${dates.enddate}`,
        {
          headers: {
            Authorization: `Bearer ${userDetails.token}`,
          },
        }
      );

      // Uncomment the following code if you want to handle the CSV data
      if (response.data.result.length > 0) {
        const csv = Papa.unparse(response.data.result);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "Bank-Transaction.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error fetching CSV data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Bank Transactions Data
                  </h6>
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-column">
                      <label
                        htmlFor="start-date"
                        // className="mx-2"
                        style={{
                          // marginTop: "3px",
                          width: "auto",
                          color: "black",
                        }}
                      >
                        Start Date:
                      </label>
                      <input
                        type="date"
                        name="startdate"
                        placeholder="Select start date"
                        value={dates.startdate}
                        onChange={handleDates}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </div>
                    <div className="mx-2 d-flex flex-column">
                      <label
                        htmlFor="start-date"
                        // className="mx-2"
                        style={{
                          // marginTop: "3px",
                          width: "auto",
                          color: "black",
                        }}
                      >
                        End Date:
                      </label>
                      <input
                        type="date"
                        name="enddate"
                        placeholder="Select end date"
                        value={dates.enddate}
                        onChange={handleDates}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </div>
                    <div className="mx-0.5 d-flex flex-column">
                      <label
                        htmlFor="column"
                        // className="mx-2"
                        style={{
                          // marginTop: "3px",
                          width: "auto",
                          color: "black",
                        }}
                      >
                        Column Head:
                      </label>
                      <select
                        value={searchColumn}
                        onChange={(e) => setSearchColumn(e.target.value)}
                        style={{ height: "30px" }}
                      >
                        <option value={""}>Select One</option>
                        {/* <option value={"description"}>Description</option> */}
                        <option value={"device"}>Device Name</option>
                        <option value={"bank_name"}>Bank Name</option>
                        <option value={"account_title"}>Account Title</option>
                        <option value={"account_number"}>Account Number</option>
                        <option value={"department"}>Department</option>
                        <option value={"amount"}>Amount</option>
                        <option value={"available"}>Account Balance</option>
                        <option value={"available_balance"}>
                          Available Balance
                        </option>
                        <option value={"channel"}>Channel</option>
                        <option value={"txn_type"}>Type of Txn</option>
                        <option value={"type"}>Dr/Cr</option>
                        <option value={"refNo"}>Ref No/RRN/UTR</option>
                        <option value={"tr_created_date"}>
                          Tr Date and Time
                        </option>
                        <option value={"transaction_type"}>
                          Transaction Type
                        </option>
                      </select>
                    </div>
                    <div className="mx-2 d-flex flex-column">
                      <label
                        htmlFor="start-date"
                        // className="mx-2"
                        style={{
                          // marginTop: "3px",
                          width: "auto",
                          color: "black",
                        }}
                      >
                        Column Value:
                      </label>
                      <input
                        type="text"
                        placeholder="Enter for search here..."
                        name="filter"
                        value={searchData}
                        onChange={(e) => setSearchData(e.target.value)}
                      />
                    </div>

                    <div style={{ position: "relative", top: "15px" }}>
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={toggleFilter}
                        style={{ whiteSpace: "nowrap", width: "110px" }}
                        disabled={
                          !(dates.startdate && dates.enddate) &&
                          (searchData.trim() === "" ||
                            searchColumn.trim() === "")
                        }
                      >
                        {filterActive ? "Clear Filter" : "Apply Filter"}
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary form-control mx-2"
                        onClick={downloadCSV}
                        style={{ width: "110px" }}
                      >
                        Download
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    {loading && (
                      <div className="loader-container">
                        <div
                          className="spinner-border text-primary loader"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}

                    <DataTable
                      columns={columns}
                      data={data}
                      pagination
                      paginationServer
                      paginationDefaultPage={currentPage}
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerPageChange}
                      onChangePage={handlePageChange}
                      responsive={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>Copyright &copy; Blue Iris Software Trading LLC</span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Transactions;
