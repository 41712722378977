import Home from "./components/Home";
import Transactions from "./components/Transactions";
import Devices from "./components/DevicesTable";
import EditDevice from "./components/EditDevice";
import Login from "./components/Login";
import Logout from "./components/Logout";
import TransactionCSV from "./components/DownloadCSV";
import ExcelUpload from "./components/excelUpload";
import APK from "./components/APKDownload";
import Protected from "./components/Protected";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            <Protected protect={false}>
              <Login />
            </Protected>
          }
        />
        <Route
          path="/"
          element={
            <Protected protect={true}>
              <Home />
            </Protected>
          }
        />
        <Route
          path="/transactions"
          element={
            <Protected protect={true}>
              <Transactions />
            </Protected>
          }
        />
        <Route
          path="/devices"
          element={
            <Protected protect={true}>
              <Devices />
            </Protected>
          }
        />
        <Route
          path="/devices/edit/:id"
          element={
            <Protected protect={true}>
              <EditDevice />
            </Protected>
          }
        />
        <Route
          path="/logout"
          element={
            <Protected protect={true}>
              <Logout />
            </Protected>
          }
        />
        <Route
          path="/transactions-csv"
          element={
            <Protected protect={true}>
              <TransactionCSV />
            </Protected>
          }
        />
        <Route
          path="/excel-upload"
          element={
            <Protected protect={true}>
              <ExcelUpload />
            </Protected>
          }
        />
        <Route
          path="/apk-download"
          element={
            <Protected protect={true}>
              <APK />
            </Protected>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
