import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";

const DeviceTable = ({ action, filter, dates, column }) => {
  const [deviceData, setDeviceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [comments, setComments] = useState({});
  // const userDetails = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  // if (!userDetails || !userDetails.token) {
  //   navigate("/logout");
  // }

  const fetchData = (page, limit, filter, action, dates, column) => {
    const userDetails = JSON.parse(localStorage.getItem("user"));

    if (!userDetails || !userDetails.token) {
      navigate("/logout");
    }

    if (
      action !== false &&
      (filter !== "" || (dates.startDate !== "" && dates.endDate !== ""))
    ) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}devices-filter?page=${page}&limit=${limit}&filter=${filter}&column=${column}&startdate=${dates.startDate}&enddate=${dates.endDate}`,
          {
            headers: {
              Authorization: `Bearer ${userDetails.token}`,
            },
          }
        )
        .then((response) => {
          setDeviceData(response.data.result);
          setTotalRecords(response.data.totalRecords);
        })
        .catch((error) => {
          console.error("Error fetching devices data:", error);
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}devices-list?page=${page}&limit=${limit}`,
          {
            headers: {
              Authorization: `Bearer ${userDetails.token}`,
            },
          }
        )
        .then((response) => {
          setDeviceData(response.data.result);
          setTotalRecords(response.data.totalRecords);
        })
        .catch((error) => {
          console.error("Error fetching devices data:", error);
        });
    }
  };

  useEffect(() => {
    fetchData(currentPage, rowsPerPage, filter, action, dates, column);
  }, [filter, action, dates, column, currentPage, rowsPerPage]);

  useEffect(() => {
    setCurrentPage(1); // Reset to first page when filter or dates change
  }, [filter, action, dates]);

  const saveComment = (event, deviceId) => {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (!userDetails || !userDetails.token) {
      navigate("/logout");
    }

    axios
      .post(
        process.env.REACT_APP_BASE_URL + "comment-create",
        {
          comment: event.target.value,
          device_id: deviceId,
        },
        {
          headers: {
            Authorization: `Bearer ${userDetails.token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
        } else if (response.status === 202) {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // toast.error(error.response.data.message[0]);
      });
  };

  const handleStatusChange = (id, event) => {
    const newValue = event.target.checked ? 1 : 0;
    const updatedDevices = deviceData.map((device) =>
      device._id === id ? { ...device, status: newValue } : device
    );
    setDeviceData(updatedDevices);

    const userDetails = JSON.parse(localStorage.getItem("user"));

    if (!userDetails || !userDetails.token) {
      navigate("/logout");
    }

    axios
      .post(
        process.env.REACT_APP_BASE_URL + "device-status-update",
        {
          device_id: id,
          status: newValue,
        },
        {
          headers: {
            Authorization: `Bearer ${userDetails.token}`,
          },
        }
      )
      .then((response) => {
        newValue === 1
          ? toast.success("The device has been enabled successfully.")
          : toast.error("The device has been disabled successfully.");
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newPerPage) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(1);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";

    return moment.utc(dateString).format("YYYY-MM-DD hh:mm:ss A");
  };

  const deleteDevice = (id) => {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (!userDetails || !userDetails.token) {
      navigate("/logout");
    }

    const isConfirmed = window.confirm(
      "Are you sure you want to delete this device?"
    );
    if (isConfirmed) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}delete-device/${id}`, {
          headers: {
            Authorization: `Bearer ${userDetails.token}`,
          },
        })
        .then((response) => {
          toast.error(response.data.message);
          // Optionally refresh the data after deletion
          fetchData(currentPage, rowsPerPage, filter, action, dates);
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    }
  };

  const editDevice = (deviceId) => {
    navigate(`/devices/edit/${deviceId}`);
  };

  const deviceColumns = [
    {
      name: "Sr No.",
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
      width: "100px",
    },
    {
      name: "Device",
      selector: (row) => row.name,
    },
    {
      name: "Bank Name",
      selector: (row) => (row && row.bank_name ? row.bank_name : "N/A"),
    },
    {
      name: "Account Title",
      selector: (row) => (row && row.account_title ? row.account_title : "N/A"),
    },
    {
      name: "Account Number",
      selector: (row) =>
        row && row.account_number ? row.account_number : "N/A",
    },
    {
      name: "Department",
      selector: (row) =>
        row.department && row.department.name ? row.department.name : "N/A",
    },
    {
      name: "Action",
      selector: (row, index) => (
        <div className="custom-control custom-switch align-self-center">
          <input
            type="checkbox"
            className="custom-control-input"
            id={"switch" + (index + 1)}
            name="id"
            value={row._id}
            checked={row.status === 1}
            onChange={(event) => handleStatusChange(row._id, event)}
          />
          <label
            className="custom-control-label mt-2"
            htmlFor={"switch" + (index + 1)}
          ></label>
          <button
            type="button"
            className="btn btn-sm btn-info mr-1"
            onClick={() => editDevice(row._id)}
          >
            <i
              className="fas fa-pencil-alt"
              style={{ fontSize: "14px", color: "white" }}
            ></i>
          </button>
          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={(e) => deleteDevice(row._id)}
          >
            <i
              className="fas fa-trash-alt"
              style={{ fontSize: "14px", color: "white" }}
            ></i>
          </button>
        </div>
      ),
    },
    {
      name: "Comment",
      selector: (row) => (
        <div className="d-flex align-items-center">
          <textarea
            name="comment"
            cols="50"
            placeholder="Comment here..."
            value={
              comments[row._id] !== undefined
                ? comments[row._id]
                : (row.comment && row.comment.comment) || ""
            }
            onChange={(e) => {
              const newComments = { ...comments, [row._id]: e.target.value };
              setComments(newComments);
            }}
            onBlur={(e) => {
              saveComment(e, row._id);
            }}
          ></textarea>
        </div>
      ),
    },
    {
      name: "CreatedDate",
      selector: (row) => {
        if (!row.createdAt) return "";

        return formatDate(row.createdAt);
      },
    },
  ];

  return (
    <div>
      <ToastContainer containerId="containerB" />
      <DataTable
        columns={deviceColumns}
        data={deviceData}
        pagination
        paginationServer
        paginationDefaultPage={currentPage}
        paginationTotalRows={totalRecords}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </div>
  );
};

export default DeviceTable;
