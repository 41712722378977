import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditDevice = () => {
  const { id } = useParams(); // Get deviceId from URL
  const [deviceData, setDeviceData] = useState({
    name: "",
    bank_name: "",
    account_title: "",
    account_number: "",
    department: "",
  });
  const [departments, setDepartments] = useState([]);
  const navigate = useNavigate();

  // Fetch device details
  useEffect(() => {
    const fetchDevice = async () => {
      try {
        const userDetails = JSON.parse(localStorage.getItem("user"));
        if (!userDetails || !userDetails.token) {
          navigate("/logout");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}devices/${id}`,
          {
            headers: {
              Authorization: `Bearer ${userDetails.token}`,
            },
          }
        );
        setDeviceData(response.data);
      } catch (error) {
        console.error("Error fetching device details:", error);
      }
    };

    fetchDevice();
  }, [id, navigate]);

  // Fetch department list
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}department`
        );
        setDepartments(response.data.result);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Handle department dropdown separately
    if (name === "department") {
      setDeviceData({ ...deviceData, department: value }); // Set department as ID string
    } else {
      setDeviceData({ ...deviceData, [name]: value });
    }
  };

  // Submit updated device details
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userDetails = JSON.parse(localStorage.getItem("user"));
      if (!userDetails || !userDetails.token) {
        navigate("/logout");
        return;
      }
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}devices/${id}`,
        deviceData,
        {
          headers: {
            Authorization: `Bearer ${userDetails.token}`,
          },
        }
      );
      //toast.success("Device updated successfully!");
      toast.success("Device updated successfully!", {
        onClose: () => navigate("/devices"),
      });
    } catch (error) {
      toast.error("Failed to update device. Please try again.");
      console.error("Error updating device:", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <form onSubmit={handleSubmit} className="card p-4 shadow">
                    <h3 className="text-center mb-4">Edit Device</h3>
                    <div className="form-group">
                      <label>Device Name:</label>
                      <input
                        type="text"
                        name="name"
                        value={deviceData.name}
                        className="form-control"
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Bank Name:</label>
                      <input
                        type="text"
                        name="bank_name"
                        value={deviceData.bank_name}
                        className="form-control"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Account Title:</label>
                      <input
                        type="text"
                        name="account_title"
                        value={deviceData.account_title}
                        className="form-control"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Account Number:</label>
                      <input
                        type="text"
                        name="account_number"
                        value={deviceData.account_number}
                        className="form-control"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Department:</label>
                      <select
                        name="department"
                        value={
                          typeof deviceData.department === "object"
                            ? deviceData.department._id
                            : deviceData.department || ""
                        }
                        className="form-control"
                        onChange={handleInputChange}
                      >
                        <option value="" disabled>
                          Select a Department
                        </option>
                        {departments.map((dept) => (
                          <option key={dept._id} value={dept._id}>
                            {dept.name.trim()}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group text-center">
                      <button type="submit" className="btn btn-primary mr-2">
                        Save Changes
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => navigate("/devices")}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>Copyright &copy; Blue Iris Software Trading LLC</span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default EditDevice;
